import initAnimatedBlob from './animation-blob.js';
import initButtonForms from './button-form.js';
import initGlide from './card-slider.js';
import animationColorScroll from './scroll.js';

import './scss/main.scss';


window.addEventListener('DOMContentLoaded', () => {
    try {
        const {recaptchaCallback} = initButtonForms('.forms-container');

        window.recaptchaCallback = recaptchaCallback;
    }
    catch (e) {
        console.error(e);
    }
});

export {
    initAnimatedBlob,
    initGlide,
    animationColorScroll,
};
