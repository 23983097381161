import intlTelInput from 'intl-tel-input';

import 'intl-tel-input/build/css/intlTelInput.css';


export default function initButtonForms(containerSelector) {
    const formsContainer = document.querySelector(containerSelector);

    if (formsContainer === null) {
        throw Error(`${containerSelector} not found`);
    }

    const formButtons = formsContainer.querySelectorAll('.btn-open, .btn-close');

    if (formButtons === null) {
        throw Error(`${containerSelector} .btn-open and .btn-close not found`);
    }

    const forms = formsContainer.querySelectorAll('.animated-form');

    if (forms === null) {
        throw Error(`${containerSelector} .animated-form not found`);
    }

    formButtons.forEach(button => {
        button.addEventListener('click', function(e) {
            toggleForm(e.currentTarget);
        });
    });

    forms.forEach(form => {
        form.addEventListener('animationend', function(e) {
            // Conditional needed due to event bubbling.
            if (e.target.matches('.animated-form')) {
                endFormAnimation(e.target);
            }
        });
    });

    const phoneField = formsContainer.querySelector('.input-phone-number');

    if (phoneField === null) {
        return null;
    }

    const phoneInput = intlTelInput(phoneField, {
        preferredCountries: ['nl'],
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.5/build/js/utils.js',
    });

    const toggleForm = (buttonClicked) => {
        let targetForm;

        if (buttonClicked.matches('.btn-open')) {
            targetForm = buttonClicked.parentElement;
        }
        else if (buttonClicked.matches('.btn-close')) {
            targetForm = buttonClicked.parentElement.parentElement;
        }
        else {
            return;
        }

        if (targetForm.matches('.form-closed')) {
            /* To make sure the form will open downward
             * (Firefox opens it upward when not fully below the top of the viewport). */
            scrollFormIntoView(targetForm);

            const otherForms = getOtherForms(targetForm, forms);

            otherForms.forEach(hideForm);
            formsContainer.classList.add('display-block');
            targetForm.classList.replace('form-closed', 'form-opening');
        }
        else if (targetForm.matches('.form-open')) {
            targetForm.classList.replace('form-open', 'form-closing');
        }
    };

    const scrollFormIntoView = (form) => {
        if (form.getBoundingClientRect().top < 0) {
            window.scrollBy({
                top: form.getBoundingClientRect().top - 10,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    const getOtherForms = (targetForm, forms) => {
        return Array.from(forms).filter(form => form.getAttribute('id') !== targetForm.getAttribute('id'));
    };

    const hideForm = (form) => {
        form.classList.add('form-hidden');
    };

    const showForm = (form) => {
        form.classList.remove('form-hidden');
    };

    const endFormAnimation = (targetForm) => {
        if (targetForm.matches('.form-opening')) {
            targetForm.classList.replace('form-opening', 'form-open');
        }
        else if (targetForm.matches('.form-closing')) {
            const otherForms = getOtherForms(targetForm, forms);

            formsContainer.classList.remove('display-block');
            otherForms.forEach(showForm);
            targetForm.classList.replace('form-closing', 'form-closed');
        }
    };

    const recaptchaCallback = (token) => {
        const containerList = formsContainer.querySelectorAll('.animated-form');
        let selectedFormId = '';

        for (let i = 0; i < containerList.length; i++) {
            const containerId = containerList[i]
                .getAttribute('id')
                .split('_')[0];

            if (!containerList[i].classList.contains('form-hidden')) {
                selectedFormId = containerId + '_form';
            }
        }

        const selectedForm = document.getElementById(selectedFormId);

        if (selectedForm === null) {
            return;
        }

        const validationResult = validation(selectedForm);

        if (validationResult.success) {
            makePostRequest(token, selectedForm);
        }
        else {
            validationMessage(validationResult.fieldErrors, selectedForm);
        }
    };

    const validation = (form) => {
        const result = {
            success: true,
            fieldErrors: {},
        };

        const phoneField = form.querySelector('.input-phone-number');
        const emailField = form.querySelector('[type=email]');

        if (phoneField !== null) {
            const phonePattern = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/g;
            const phoneNumber = phoneInput.getNumber();

            if (!phonePattern.test(phoneNumber)) {
                result.success = false;
                const phoneName = phoneField.getAttribute('name');
                const phoneId = phoneField.getAttribute('id');

                result.fieldErrors[phoneName] = {
                    id: phoneId,
                    message: 'Not a valid phone number.',
                };
            }
        }

        if (emailField !== null) {
            const emailPattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

            if (!emailPattern.test(emailField.value)) {
                result.success = false;
                const emailName = emailField.getAttribute('name');
                const emailId = emailField.getAttribute('id');

                result.fieldErrors[emailName] = {
                    id: emailId,
                    message: 'Not a valid email address.',
                };
            }
        }

        return result;
    };

    const makePostRequest = (token, form) => {
        const inputElements = form.querySelectorAll('form input, form textarea');
        const csrftoken = form.querySelector('[name=csrfmiddlewaretoken]').value;
        const data = {captcha: token};

        for (let i = 0; i < inputElements.length; i++) {
            if (inputElements[i].value !== '') {
                const phoneClass = 'input-phone-number';

                if (inputElements[i].getAttribute('class') === phoneClass) {
                    const phoneNumber = phoneInput.getNumber();

                    data['phone-number'] = phoneNumber;
                }
                else {
                    const key = inputElements[i].getAttribute('name');
                    const value = inputElements[i].value;

                    data[key] = value;
                }
            }
        }

        fetch('/send/', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                Accept: 'aplication/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                formDeleteMsg(form);
                if (response.success) {
                    formSuccessful(form);
                }
                else {
                    formError(response.error, form);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const validationMessage = (fieldErrors, form) => {
        removeValidationMessagesAndStyles(form);

        for (const error in fieldErrors) {
            const errorMessage = document.createElement('p');

            errorMessage.classList.add('field-error-msg');
            errorMessage.textContent = fieldErrors[error].message;

            const fieldLabel = form.querySelector(`[for="${fieldErrors[error].id}"]`);
            const destiny = fieldLabel.parentElement;

            destiny.append(errorMessage);

            const field = fieldLabel.nextElementSibling;

            field.classList.add('field-error');
        }
    };

    const removeValidationMessagesAndStyles = (form) => {
        const errorMessages = form.querySelectorAll('.field-error-msg');

        errorMessages.forEach((message) => {
            message.remove();
        });

        const fields = form.querySelectorAll('.field-error');

        fields.forEach((field) => {
            field.classList.remove('field-error');
        });
    };

    const formSuccessful = (form) => {
        form.addEventListener('animationend', endFormSuccessClosingAnimation);
        form.classList.add('form-success-closing');
    };

    const endFormSuccessClosingAnimation = (e) => {
        // Conditional used as a precaution against event bubbling.
        if (!e.target.matches('form')) {
            return;
        }

        const targetForm = e.target;
        const formContent = targetForm.parentElement;
        const successMessage = formContent.querySelector('.form-success-msg');

        targetForm.classList.add('hidden');
        targetForm.classList.remove('form-success-closing');
        // Clean up as a precaution in case a new listener was added at some point.
        targetForm.removeEventListener('animationend', endFormSuccessClosingAnimation);
        successMessage.classList.remove('hidden');
        successMessage.addEventListener('animationend', endFormSuccessOpeningAnimation);
        successMessage.classList.add('form-success-opening');
    };

    const endFormSuccessOpeningAnimation = (e) => {
        // Conditional used as a precaution against event bubbling.
        if (!e.target.matches('.form-success-msg')) {
            return;
        }

        const successMessage = e.target;

        successMessage.classList.remove('form-success-opening');
        // Clean up as a precaution in case a new listener was added at some point.
        successMessage.removeEventListener('animationend', endFormSuccessOpeningAnimation);
    };

    const formError = (errorMsg, form) => {
        const element = document.createElement('p');

        element.classList.add('form-error-msg');
        element.textContent = errorMsg;

        form.lastElementChild.before(element);
    };

    const formDeleteMsg = (form) => {
        removeFormErrorMessage(form);
        removeValidationMessagesAndStyles(form);
    };

    const removeFormErrorMessage = (form) => {
        const errorMsg = form.querySelector('.form-error-msg');

        if (errorMsg !== null) {
            errorMsg.remove();
        }
    };

    return {
        recaptchaCallback,
    };
}
