// import Glide JS
import Glide from '@glidejs/glide';

export default function initGlide(glideClass) {
    const glide = new Glide(glideClass, {
        animationDuration: 750,
        animationTimingFunc: 'cubic-bezier(1, 1.5, 0.41, 0.79)',
        autoplay: 5000,
        bound: false,
        cloningRatio: 1,
        direction: 'ltr',
        dragThreshold: 120,
        focusAt: 'center',
        gap: 100,
        hoverpause: false,
        keyboard: true,
        peek: 0,
        perSwipe: '',
        perView: 3,
        rewind: true,
        rewindDuration: 1000,
        startAt: 0,
        swipeThreshold: 80,
        throttle: 10,
        touchAngle: 45,
        touchRatio: 0.5,
        type: 'carousel',
        waitForTransition: true,
        breakpoints: {
            800: {
                perView: 2,
            },
            480: {
                perView: 1,
            },
        },
    });

    return glide.mount({
        /*
            This codeblock makes sure the active class is given
            when starting to move the carousel not when it is done.
        */
        CustomActiveClass: (Glide, Components, Events) => {
            const Component = {
                mount() {
                    this.changeActiveSlide();
                },
                changeActiveSlide() {
                    const slide = Components.Html.slides[Glide.index];

                    // Add active animation to next index
                    slide.classList.add('glide__slide--active');

                    // Remove from all existing slides
                    [...slide.parentNode.children]
                        .filter((child) => child !== slide)
                        .forEach((sibling) => {
                            sibling.classList.remove('glide__slide--active');
                        });

                    const lastIndex = Components.Html.slides.length - 1;

                    // Fix for infinite scroll when next card
                    if (Glide.index === 0) {
                        Components.Html.slides[
                            lastIndex
                        ].nextElementSibling.classList.add(
                            'glide__slide--active',
                        );
                    }

                    // Fix for infinite scroll when prev card
                    if (Glide.index === lastIndex) {
                        Components.Html.slides[0].previousElementSibling.classList.add(
                            'glide__slide--active',
                        );
                    }
                },
            };

            Events.on('run', () => {
                Component.changeActiveSlide();
            });
            return Component;
        },
    });
}
