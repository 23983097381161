export default function initAnimatedBlob() {
    const body = document.body;
    const divAnimBg = document.createElement('div');
    const divAnimBlob1 = document.createElement('div');
    const divAnimBlob2 = document.createElement('div');

    divAnimBg.id = 'animated-background';
    divAnimBlob1.id = 'animated-blob-1';
    divAnimBlob2.id = 'animated-blob-2';

    divAnimBg.append(divAnimBlob1);
    divAnimBg.append(divAnimBlob2);
    body.prepend(divAnimBg);

    // Create blobs
    const blob1 = new Blob('animated-blob-1', new Point(getWindowCenter().x, 0));

    blob1.setSize(2000);
    blob1.setSizes(1200, 2000);
    blob1.setOffset([180, 200], [-180, -200]);
    blob1.setTarget(getWindowCenter().getCoordinates());

    const blob2 = new Blob('animated-blob-2', new Point(getWindowCenter().x, 0));

    blob2.setSize(600);
    blob2.setSizes(400, 600);
    blob2.setOffset([-10, -200], [10, 200]);
    blob2.setTarget(getWindowCenter().getCoordinates());

    addEventListener('mousemove', function(e) {
        blob1.setTarget(getMousePosition(e));
        blob2.setTarget(getMousePosition(e));
    });
    addEventListener('scroll', function() {
        blob1.setTarget([getWindowCenter().x, 2000]);
        blob2.setTarget([getWindowCenter().x, 2000]);
    });

    addEventListener('resize', function() {
        blob1.setTarget(getWindowCenter().getCoordinates());
        blob2.setTarget(getWindowCenter().getCoordinates());
    });
}

class Point {
    constructor(x = 0, y = 0) {
        this.x = x;
        this.y = y;
    }

    getCoordinates = () => {
        return [this.x, this.y];
    };

    approx = (other, distance = 1) => {
        if (this.distanceTo(other) <= distance) {
            return true;
        }

        return false;

    };

    distanceTo = (other) => {
        const dx = this.x - other.x;
        const dy = this.y - other.y;

        return (dx ** 2 + dy ** 2) ** .5;
    };
}

class Blob {
    constructor(objId = '', position = new Point(0, 0)) {
        this.obj = document.getElementById(objId);
        // properties
        this.speedMin = 1;
        this.speedMax = 10;
        this.sizeMin = 0;
        this.sizeMax = 0;
        this.offsetXMin = null;
        this.offsetXMax = null;
        this.offsetYMin = null;
        this.offsetYMax = null;
        this.agilityMin = 2;
        this.agilityMax = 20;
        this.changeRange = 400;
        this.sizeChange = 0;
        // current
        this.position = position;
        this.displayPosition = new Point(position.getCoordinates());
        this.speed = 0;
        this.agility = this.agilityMin;
        this.movement = false;
        this.size = 200;
        this.direction = 0;
        this.animating = false;
        this.animationFrameRef = null;
        this.updateTime = null;
        this.targetSize = 0;
        this.targetSpeed = 0;
        this.targetDirection = 0;
        this.targetPosition = new Point(0, 0);
        // init
        this.setPosition(this.position);
    }

    setPosition = (position) => {
        this.position = position;
        this.updateBlob();
    };

    setSize = (size) => {
        this.size = size;
        this.updateBlob();
    };

    setTarget = (position = [0, 0]) => {
        this.targetPosition.x = position[0];
        this.targetPosition.y = position[1];
        this.startMoving();
    };

    setOffset = (hor = [0, 0], ver = [0, 0]) => {
        this.offsetXMin = hor[0];
        this.offsetXMax = hor[1];
        this.offsetYMin = ver[0];
        this.offsetYMax = ver[1];
    };

    updateBlob = () => {
        this.obj.style.width = this.size + 'px';
        this.obj.style.height = this.size + 'px';
        this.obj.style.left = (this.displayPosition.x - .5 * this.size) + 'px';
        this.obj.style.top = (this.displayPosition.y - .5 * this.size) + 'px';
    };

    setSizes = (sizeMin, sizeMax) => {
        this.sizeMin = sizeMin;
        this.sizeMax = sizeMax;
        this.sizeChange = (sizeMax - sizeMin) / (this.changeRange / this.speedMax);
    };

    startMoving = () => {
        if (!this.animating) {
            this.animating = true;
            const direction = this.directionFromTo(this.position, this.targetPosition);

            this.targetDirection = direction;
            this.direction = direction;
            this.speed = this.speedMin;
            this.move();
        }
    };

    stopMoving = () => {
        this.animating = false;
        this.speed = 0;
        this.size = this.targetSize;
        this.position.x = this.targetPosition.x;
        this.position.y = this.targetPosition.y;
    };

    directionFromTo = (p1, p2) => {
        const dx = p2.x - p1.x;
        const dy = p2.y - p1.y;
        // Calculate ideal direction towards target, rotation in radians
        let rotation = Math.atan(dy / dx);

        if (dx < 0) {
            rotation = Math.PI + rotation;
        }
        else if (dx >= 0 && dy <= 0) {
            rotation = 2 * Math.PI + rotation;
        }
        return rotation;
    };

    updateDirection = () => {
        this.targetDirection = this.directionFromTo(this.position, this.targetPosition);
        // Steer towards target
        let ccw;
        let cw;

        // Calculate both distances (in angle of rotation)
        const arc = this.targetDirection - this.direction;

        if (this.targetDirection <= this.direction) {
            ccw = arc;
            cw = 2 * Math.PI + ccw;
        }
        else {
            cw = arc;
            ccw = cw - 2 * Math.PI;
        }
        let d;
        let r;

        // Choose the shortest distance
        if (Math.abs(cw) < Math.abs(ccw)) {
            r = Math.abs(cw);
            d = 1;
        }
        else {
            r = Math.abs(ccw);
            d = -1;
        }
        this.direction += d * Math.min(this.agility, r / 2);
        while (this.direction < 0) {
            this.direction += 2 * Math.PI;
        }
        this.direction %= 2 * Math.PI;
    };

    update = () => {
        let percentage = this.targetPosition.distanceTo(this.position) / this.changeRange;

        percentage = easeInOutSine(percentage);
        this.updateSpeed(percentage);
        this.updateSize(percentage);
        this.updatePosition(percentage);
    };

    updateSpeed = (percentage) => {
        this.targetSpeed = this.speedMin + percentage * (this.speedMax - this.speedMin);
        this.speed += .5 * (this.targetSpeed - this.speed);
    };

    updateSize = (percentage) => {
        this.targetSize = this.sizeMin + percentage * (this.sizeMax - this.sizeMin);
        if (this.size < this.targetSize) {
            this.size += this.sizeChange;
        }
        if (this.size > this.targetSize) {
            this.size -= this.sizeChange;
        }
    };

    updatePosition = (percentage) => {
        this.position.x += this.speed * Math.cos(this.direction);
        this.position.y += this.speed * Math.sin(this.direction);
        this.displayPosition.x = this.position.x + this.offsetXMin + percentage * (this.offsetXMax - this.offsetXMin);
        this.displayPosition.y = this.position.y + this.offsetYMin + percentage * (this.offsetYMax - this.offsetYMin);
    };

    move = () => {
        if (this.position.approx(this.targetPosition, 1)) {
            this.stopMoving();
            this.updateBlob();
            // document.getElementById('feedback').innerHTML = 'I’m stopped';
        }
        else {
            this.updateDirection();
            this.update();
            this.updateBlob();
            this.animationFrameRef = requestAnimationFrame(() => this.move());
            this.updateTime = Date.now();
        }
    };
}

const easeInOutSine = (factor) => {
    return (1 - Math.cos(factor * Math.PI)) / 2;
};

const getWindowCenter = () => {
    const centerHor = Math.round(window.innerWidth / 2);
    const centerVer = Math.round(window.innerHeight / 2);

    return new Point(centerHor, centerVer);
};

const getMousePosition = (e) => {
    return [e.clientX, e.clientY];
};
