/*
* Calculate the position of the user in the page to display the right color
* stage for the entire page. Also, will mix the colors.
*/
export default function animationColorScroll() {
    const body = document.body;

    if (!body.classList.contains('bg-purple-sand')) {
        return;
    }

    const html = document.documentElement;
    const pageHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
    );
    const innerHeight = window.innerHeight - 1;

    window.addEventListener('scroll', () => {
        let percentage = Math.round(window.scrollY / (pageHeight - innerHeight) * 100);

        if (percentage > 100) {
            percentage = 100;
        }
        else if (percentage < 0) {
            percentage = 0;
        }
        document.documentElement.style.setProperty(
            '--dark-purple-sand',
            `color-mix(in srgb, #3e2510 ${percentage}%, #220c38)`,
        );
        document.documentElement.style.setProperty(
            '--light-purple-sand',
            `color-mix(in srgb, #fcdcc1 ${percentage}%, #f7e3ff)`,
        );
        document.documentElement.style.setProperty(
            '--bright-purple-sand',
            `color-mix(in srgb, #efa96f ${percentage}%, #d87bfb)`,
        );
        document.documentElement.style.setProperty(
            '--regular-purple-sand',
            `color-mix(in srgb, #cf762c ${percentage}%, #863aa3)`,
        );
        document.documentElement.style.setProperty(
            '--dark-green-blue',
            `color-mix(in srgb, #002133 ${percentage}%, #0f4123)`,
        );
        document.documentElement.style.setProperty(
            '--bright-green-blue',
            `color-mix(in srgb, #58bbef ${percentage}%, #52ce84)`,
        );
        document.documentElement.style.setProperty(
            '--light-green-blue',
            `color-mix(in srgb, #c5ebff ${percentage}%, #bcf6d3)`,
        );
        document.documentElement.style.setProperty(
            '--regular-green-blue',
            `color-mix(in srgb, #0572ab ${percentage}%, #0fa44b)`,
        );
        document.documentElement.style.setProperty(
            '--animcolor1a',
            `color-mix(
                in srgb,
                rgb(207, 118, 44, 100%) ${percentage}%,
                rgb(134, 58, 163, 100%)
            )`,
        );
        document.documentElement.style.setProperty(
            '--animcolor1b',
            `color-mix(
                in srgb,
                rgb(207, 118, 44, 0%) ${percentage}%,
                rgb(134, 58, 163, 0%)
            )`,
        );
    }, false);
}
